<template>
  <div>
    <b-row>
      <b-col>
        <b-card no-body :style="{ maxHeight: '85vh', overflow: 'auto' }">
          <b-card-body class="px-0">
            <b-card-text class="d-flex mx-1">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>

                <b-form-input
                  v-model="searchQuery"
                  :placeholder="lang('t_search')"
                ></b-form-input>
              </b-input-group>

              <b-button
                v-b-tooltip.hover.top="lang('t_add')"
                v-b-tooltip.hover.v-secondary
                @click="
                  modal_record_details = true;
                  isEdit = false;
                  reset_record();
                "
                size="sm"
                class="px-50 py-50 ml-2"
                variant="flat-secondary"
              >
                <b-icon icon="plus"></b-icon>
              </b-button>
              <b-dropdown
                dropleft
                boundary="viewport"
                size="sm"
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item
                  @click="
                    modal_excel = true;
                    isEdit = false;
                    reset_record();
                  "
                >
                  <b-icon icon="upload"></b-icon>
                  <span class="align-middle ml-50">{{
                    lang("t_importRecords")
                  }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-card-text>
            <b-table
              :filter="searchQuery"
              small
              style="max-height: 100%"
              hover
              :fields="tableColumns"
              :items="records"
              responsive
              :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <!-- <template #cell(name)="data">
                <b-avatar :variant="'light-success'">
                  {{
                    data.value.substr(0, 1).toUpperCase() +
                    data.value.split(" ")[1].substr(0, 1).toUpperCase()
                  }}
                </b-avatar>
                <span class="ml-1">{{ data.value }}</span>
              </template> -->
              <template #cell(queuename)="data">
                <b-badge variant="light-primary">
                  {{
                    queues.find((e) => e.internal_name == data.value)
                      ? queues.find((e) => e.internal_name == data.value)
                          .display_name
                      : data.value
                  }}
                </b-badge>
              </template>
              <template #cell(create_date)="data">
                {{ convertDateFormat(data.value) }}
              </template>
              <template #cell(call_direction)="data">
                <b-icon
                  icon="telephone-inbound"
                  v-if="data.value == 'inbound'"
                ></b-icon>
                <b-icon
                  icon="telephone-outbound"
                  v-if="data.value == 'outbound'"
                ></b-icon>
                <b-icon
                  icon="telephone-outbound"
                  v-if="data.value == 'dialer'"
                ></b-icon>
              </template>
              <template #cell(phone)="data">
                <a href="#" @click="start_call(data.value)">
                  {{ GetRemoteNumber(data.value) }}</a
                >
              </template>
              <template #cell(actions)="data">
                <b-dropdown
                  dropleft
                  boundary="viewport"
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="updateSelected(data.item)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="confirmText(data.item)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">{{
                      lang("t_delete")
                    }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
              <template #cell(rate)="data">
                <b-progress
                  show-value
                  :value="(data.value * 100) / extra.total_finish_code_counts"
                  :max="100"
                  :variant="
                    parseInt(
                      (data.value / extra.total_finish_code_counts) * 100
                    ) > 40
                      ? 'success'
                      : 'warning'
                  "
                />
              </template>

              <template #cell(category)="data">
                <center>
                  <b-badge :variant="'light-secondary'">
                    {{ data.value }}
                  </b-badge>
                </center>
              </template>
            </b-table>
          </b-card-body>

          <b-card-footer class="py-75 border-0">
            <div
              class="d-flex justify-content-between d-flex align-items-center"
            >
              <div class="d-flex align-items-center mb-0">
                <!-- <span class="text-nowrap"> Showing 1 to </span> -->
                <b-form-select
                  size="sm"
                  v-model="perPage"
                  :options="['10', '25', '50']"
                  class="mr-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />

                <span class="text-nowrap text-muted font-small-3">
                  {{
                    lang("t_total") +
                    " " +
                    records.length +
                    " " +
                    lang("t_row").toLowerCase()
                  }}
                </span>
              </div>

              <b-pagination
                size="sm"
                class="mb-0"
                v-model="currentPage"
                :per-page="perPage"
                align="right"
                :total-rows="records.length"
                first-number
                last-number
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="16" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="16" />
                </template>
              </b-pagination>
            </div>
          </b-card-footer>
        </b-card>

        <b-modal
          :title="isEdit ? lang('t_edit') : lang('t_add')"
          centered
          v-model="modal_record_details"
          :ok-title="lang('t_save')"
          :cancel-title="lang('t_cancel')"
          @ok="add_record"
        >
          <b-form-group :label="lang('t_nameSurname')" label-for="namesurname">
            <b-form-input
              id="namesurname"
              v-model="currentData.name"
              autofocus
              trim
            />
          </b-form-group>

          <!-- Username -->

          <b-form-group :label="lang('t_number')" label-for="phone">
            <b-form-input id="phone" v-model="currentData.phone" trim />
          </b-form-group>

          <!-- EMail -->
          <b-form-group :label="lang('t_email')" label-for="email">
            <b-form-input id="email" v-model="currentData.email" trim />
          </b-form-group>

          <!-- Note -->
          <b-form-group :label="lang('t_note')" label-for="note">
            <b-form-textarea
              id="note"
              v-model="currentData.note"
              :placeholder="lang('t_note')"
              rows="5"
              trim
            >
            </b-form-textarea>
          </b-form-group>
        </b-modal>
        <b-modal
          no-close-on-esc
          no-close-on-backdrop
          @ok="read_data"
          v-model="modal_excel"
          :title="lang('t_importRecords')"
          centered
        >
          <b-row align-h="end">
            <b-button
              target="_blank"
              href="template_examples/voyce_phone_book_import.xlsx"
              :exact="true"
              size="sm"
              variant="flat-success"
              >{{ lang("t_exampleFile") }}</b-button
            >
          </b-row>

          <b-form-group :label="lang('t_file')" label-for="file">
            <b-form-file
              :browse-text="lang('t_file')"
              v-model="files"
              :placeholder="lang('t_uploadFileOnClick')"
              :drop-placeholder="lang('t_uploadFile')"
              no-drop
              required
              accept=".xlsx, .xls"
            />
          </b-form-group>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormFile,
  BCardText,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BCardFooter,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  BInputGroup,
  BInputGroupPrepend,
  BFormSelect,
  VBTooltip,
  BFormTextarea
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useList from "./useList";
import storeModule from "../storeModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, password } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BFormFile,
    BCardText,
    BFormTextarea,
    BCardTitle,
    BCardSubTitle,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    BCardBody,
    BCardFooter,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,


    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      isEdit: false,
      files: {},
      currentData: {
        id: "",
        name: "",
        phone: "",
        create_date: "",
        note: '',
        email: ''
      },
      modal_record_details: false,
      modal_excel: false,
      required,
      alphaNum,
      email,
      password,
      records: [],
      tableColumns: [
        {
          key: "name",
          label: globalThis._lang("t_nameSurname"),
          sortable: true,
        },
        { key: "phone", label: globalThis._lang("t_number"), sortable: true },
        { key: "email", label: globalThis._lang("t_email"), sortable: true },
        { key: "note", label: globalThis._lang("t_note"), sortable: true },
        // {
        //   key: "create_date",
        //   label: globalThis._lang("t_date"),
        //   sortable: true,
        // },
        { key: "actions", label: '', thStyle: { width: "5vh" }, },
      ],
    };
  },
  mounted: function () {
    this.get_records();
  },
  methods: {
    async read_data() {
      this.progress = true;
      const reader = new FileReader();
      reader.onload = (e) => {
        const bstr = e.target.result;
        const wb = globalThis.XLSX.read(bstr, { type: "binary" });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = globalThis.XLSX.utils.sheet_to_json(ws, {
          header: 1,
          raw: false,
        });
        var _data = this.xlsxToJson(data);

        for (const item of _data) {
          this.currentData = {
            name: item["name"],
            phone: item["phone"],
            email: item["email"],
            note: item["note"],
          };
          console.log("Data", this.currentData);
          this.add_record();
        }
        this.progress = false;
        this.modal_excel = false;
      };
      reader.readAsBinaryString(this.files);
    },

    xlsxToJson(data) {
      var jsonData = [];
      let headers = data[0];
      let _data = data.slice(1);
      let objectHolder = [];
      for (let row of _data) {
        let _row = [];
        for (var i = 0; i < row.length; i++) {
          _row.push([headers[i], row[i]]);
        }
        jsonData.push(Object.fromEntries(_row));
      }
      return jsonData;
    },

    convertDateFormat(inputDate) {
      const [date, time] = inputDate.split(' ');

      const [year, month, day] = date.split('-');

      const [hour, minute] = time.split(':');

      return `${day}/${month}/${year} ${hour}:${minute}`;
    },
    GetRemoteNumber(phone) {
      if (globalThis.v_navbar.other_permission.includes("mask_remote_number")) {
        return globalThis.maskPhoneNumber(phone.split(' ').join('').replace(/\D+/g, ''));
      } else {
        return phone.split(' ').join('').replace(/\D+/g, '');
      }
    },
    start_call(phone) {
      globalThis.LayoutContentRendererDefault.preview_call_request(phone);
    },
    get_records: async function () {
      var response = (await this.$http_in.get(`agent/v1/PhoneBook`)).data;
      console.log("PhoneBook", response);
      this.records = response;
    },
    check_data() {
      if (this.currentData.phone == "" || this.currentData.name == "") {
        return true;
      }
      return false;
    },
    add_record: async function () {
      if (this.check_data()) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }

      var response;
      if (!this.isEdit) {
        response = (
          await this.$http_in.post(`agent/v1/PhoneBook`, {
            ContactInfo: JSON.stringify(this.currentData),
          })
        ).data;
      } else {
        response = (
          await this.$http_in.put(`agent/v1/PhoneBook`, {
            ContactInfo: JSON.stringify(this.currentData),
          })
        ).data;
      }

      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.reset_record();
      this.get_records();
    },
    delete_record: async function () {
      var response = (
        await this.$http_in.delete(`agent/v1/PhoneBook/${this.currentData._id}/${this.currentData.internal_name}`)
      ).data;

      if (response != null || response != undefined) {
        this.$swal({
          icon: "success",
          title: this.lang("t_processSuccess"),
          text: this.lang("t_customersHaveBeenSuccessfullyDeleted"),
          confirmButtonText: this.lang("t_ok"),
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }

      this.reset_record();
      this.get_records();
    },
    reset_record() {
      this.isEdit = false;
      this.currentData = {
        id: "",
        name: "",
        phone: "",
        create_date: "",
        note: '',
        email: ''
      };
    },
    updateSelected(item) {
      this.currentData = item;
      console.log("item", item);
      this.isEdit = true;
      this.modal_record_details = true;
      // this.isAddNewUserSidebarActive = true;
    },
    confirmText(item) {
      this.currentData = item;
      console.log("confirmText", item);
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.delete_record();
        }
      });
    },
    deleteRecord: async function () {
      // Vue.$toast('Kayıt silme.');

      this.fetchData();
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    const toast = Vue.$toast;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const blankUserData = {
      fullName: "",
      username: "",
      email: "",
      role: null,
      currentPlan: null,
      company: "",
      contact: "",
    };

    const resetuserData = () => {
      currentData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    const isAddNewUserSidebarActive = ref(false);

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    var {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      toast,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
